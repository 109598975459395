<template>
  <div>
    <the-breadcrumbs
      :custom-breadcrumbs="item.parent ? breadcrumbs : []"
    ></the-breadcrumbs>
    <page-content :page-title="pageTitle">
      <guide-item-form
        :loading="loading"
        :item="item"
        :form-submitted="saveItem"
        mode="edit"
      ></guide-item-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import GuideItemForm from "@/components/forms/GuideItemForm"
import GuideItemsRepository from "@/repositories/guide_items_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Element bearbeiten"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    GuideItemForm
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: true,
      item: {}
    }
  },
  computed: {
    pageTitle() {
      if (this.item.type == "articles") {
        return "Artikelsammlung bearbeiten"
      } else if (this.item.type == "institutions") {
        return "Einrichtungssammlung bearbeiten"
      } else if (this.item.is_link) {
        return "Verlinkung bearbeiten"
      } else {
        return "Ordner bearbeiten"
      }
    },
    breadcrumbs() {
      return [
        {
          path: "/guide",
          text: "Wissenswertes"
        },
        {
          path: `/guide_items/${this.item.parent.id}`,
          text: this.item.parent.name
        }
      ]
    }
  },
  async created() {
    try {
      this.item = await GuideItemsRepository.get(this.$route.params.id)
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    async saveItem(item) {
      await GuideItemsRepository.update(item.id, item)
      if (this.item.guide_item_id) {
        this.$router.push({
          name: "ShowGuideItemPage",
          params: { id: this.item.guide_item_id }
        })
      } else {
        this.$router.push({ name: "GuidePage" })
      }
    }
  }
}
</script>
